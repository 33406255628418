import { createReducer } from "deox";
import produce from "immer";

import { getRatings } from "./actions";

const initialState = {
  ratings: { data: [] },
  loading: false,
};

export const ratingsReducer = createReducer(initialState, (handle) => [
  handle(getRatings.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    })
  ),
  handle(getRatings.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.ratings = payload;
    })
  ),
]);
