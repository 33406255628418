import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Table,
  CardHeader,
  Divider,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  Chip,
  Icon,
  ListItem,
  Grid,
  List,
  TableContainer,
  TableHead,
  Typography,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";

const useStyles = makeStyles((theme) => ({
  root: {},
  mobileHeading: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem", // 14px
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: theme.typography.lineHeightTypography,
    },
  },
}));

const LastTrainings = ({ className, ...rest }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();
  const { lastTrainingsList } = useSelector((state) => state.coach);
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title={t("coach.cabinet.last_trainings")} />
      <Divider />
      <Box>
        <Table>
          <TableBody>
            {lastTrainingsList &&
              lastTrainingsList.map((training) => (
                <TableRow key={training.id} hover className={classes.row}>
                  <TableCell>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Typography
                          variant="h5"
                          className={classes.mobileHeading}
                        >
                          {training.name}
                        </Typography>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item>
                          <Typography variant="body2">
                            {training.students.length}{" "}
                            {t("coach.cabinet.student")}
                          </Typography>
                        </Grid>
                        <Grid item>
                          {training.problemsCount} {t("coach.cabinet.problems")}
                        </Grid>
                        <Grid item>
                          {training.categories.length}{" "}
                          {t("coach.cabinet.topic")}
                        </Grid>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align="right">
                    <Chip
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/coach/trainings/${training.id}`);
                      }}
                      icon={<EditIcon />}
                      color="primary"
                      label={t("coach.cabinet.in_progress")}
                      variant="outlined"
                      clickable
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

LastTrainings.propTypes = {
  className: PropTypes.string,
};

export default LastTrainings;
