import React from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";
import Page from "components/Page";

const useStyles = makeStyles((theme) => ({
  page: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "300px",
  },
}));

const Loading = (modal) => {
  const classes = useStyles();
  return (
    <Page className={modal ? classes.modal : classes.page}>
      <CircularProgress />
    </Page>
  );
};

export default Loading;
