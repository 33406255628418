import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  makeStyles,
  CardContent,
  Card,
  Typography,
  Divider,
  Box,
  Avatar,
  Table,
  CardHeader,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Button,
  Tooltip,
  TableContainer,
  MenuItem,
  TableHead,
  TablePagination,
  List,
  Select,
  ListItem,
  CircularProgress,
  ListItemText,
} from "@material-ui/core";
import Page from "components/Page";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { awards } from "modules/user/actions";
import Fade from "@material-ui/core/Fade";
import { useAction } from "utils/hooks";
import moment from "moment";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import Loading from "components/Loading";
import { getRatings } from "modules/rating/actions";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
  },
  avatar: {
    width: 100,
    height: 100,
  },
  awardsSection: {
    width: "100%",
    height: "100%",
  },
  earned: {
    fontSize: 62,
  },
}));

const RatingsView = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { username } = useParams();
  const { name, allAwards } = useSelector((state) => state.user);
  const [periodState, setPeriod] = useState([]);
  const [limit] = useState(50);
  const getAwards = useAction(awards.request);
  const { ratings } = useSelector((state) => state.ratings);
  const [page, setPage] = useState(0);
  const [ratingsState, setRatings] = useState(ratings.data);
  const getRatingsList = useAction(getRatings.request);
  const navigate = useNavigate();

  useEffect(() => {
    getRatingsList(page);
  }, []);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    getRatingsList({ page: newPage + 1, period: periodState });
  };

  return (
    <>
      <Page className={classes.root} title="Ratings">
        <Container maxWidth="xl">
          <Grid component={Card} container className={classes.awardsSection}>
            <Grid container>
              <Grid container justifyContent="space-between">
                <Grid item md={7}>
                  <Box style={{ padding: 20 }}>
                    <Typography color="textPrimary" gutterBottom variant="h4">
                      Топ лучших игроков по рейтингу
                    </Typography>
                  </Box>
                </Grid>
                {ratings.data.length ? (
                  <TablePagination
                    component="div"
                    count={ratings.total}
                    onPageChange={handlePageChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[50]}
                  />
                ) : (
                  ""
                )}
              </Grid>
              <Grid xs={12} item>
                {ratings.data.length === 0 ? (
                  <Loading />
                ) : Boolean(ratings.data.length) ? (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Шахматист</TableCell>
                          <TableCell>Рейтинг</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ratings.data.map((user, index) => (
                          <TableRow
                            hover
                            key={user.user.id}
                            className={classes.row}
                          >
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{user.user.name}</TableCell>
                            <TableCell>{user.totalRatingChange}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <CardContent>
                    <Grid container spacing={3} justifyContent="center">
                      <Grid item>
                        <Typography variant="h4"></Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
};

export default RatingsView;
