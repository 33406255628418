import { createAction } from "deox";

export const getRatings = {
  request: createAction(
    "common/GET_RATINGS_LIST",
    (res) => (data) => res(data)
  ),
  success: createAction(
    "common/GET_RATINGS_LIST_SUCCESS",
    (res) => (data) => res(data)
  ),
  fail: createAction(
    "common/GET_RATINGS_LIST_FAIL",
    (res) => (err) => res(err)
  ),
};
