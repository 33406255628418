import { camelizeKeys } from "humps";
import { takeLatest, put } from "redux-saga/effects";

import { Api } from "api";

import { processRequestError } from "../errors";

import { getRatings } from "./actions";

function* getRatingsSaga({ payload }) {
  console.log(payload);
  try {
    const { data } = yield Api.getRatings(payload);

    yield put(getRatings.success(camelizeKeys(data)));
  } catch (error) {
    yield put(processRequestError({ error, failAction: getRatings.fail }));
  }
}

export function* watchRatings() {
  yield takeLatest(getRatings.request, getRatingsSaga);
}
