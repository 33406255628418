import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const CopyableButton = ({ text }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(text);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Button
        fullWidth
        variant="outlined"
        color="secondary"
        onClick={handleCopyClick}
      >
        {text}
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          Fen code copied to clipboard!
        </Alert>
      </Snackbar>
    </div>
  );
};

CopyableButton.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CopyableButton;
