import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import YouTube from "react-youtube";
import parse from "url-parse";

const TrainingVideo = () => {
  const { currentTraining } = useSelector((state) => state.coach);

  function getVideoIdFromUrl(url) {
    const match = url.match(/(?:\?|&)v=([^&]+)/);
    return match ? match[1] : null;
  }

  // Assuming you have the YouTube link stored in your Redux state as videoLink
  const videoLink = currentTraining.videoLink;

  // YouTube video ID
  const videoId = getVideoIdFromUrl(videoLink);

  console.log(videoId);
  console.log(videoLink);

  const opts = {
    height: "360",
    width: "100%",
    playerVars: {
      // You can add additional YouTube player parameters here
      autoplay: 0,
    },
  };

  return (
    <Card className="w-100">
      <CardContent>
        <YouTube videoId={videoId} opts={opts} />
      </CardContent>
    </Card>
  );
};

TrainingVideo.propTypes = {
  className: PropTypes.string,
};

export default TrainingVideo;
